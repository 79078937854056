<template>
    <Content
title="Cadastrar rota"
:action="action" :hasBack="action" @event="createRoute"
route="/rotas"
>
<template #selects>
<b-row style="flex-direction: row; margin-top: 1%;">
  <b-col cols="4">
<b-form-group id="select-title">
            <b-form-select
                id="sort-by-select"
                :options="options"
                v-model="selectedSystem"
                class="select"
            >
               <template #first>
                <b-form-select-option :value="'selecionar'"
                    >Selecionar sistema</b-form-select-option
                >
               </template>
            </b-form-select>
            </b-form-group>
  </b-col></b-row>
</template>
<b-form ref="form">
<b-row class="card" id="clonar_dicionarios2">

 <!-- row do form -->
   <b-row>
    <b-col v-for="field in fieldsExtra" :key="field.key" :cols="field.col">
      <b-form-group :id="'input-add-group' + field.key">
     <!--    <div class="h-separator"> -->
          <label :for="'input-add-' + field.key" class="title"
            >{{ field.label }}{{ field.required ? " *" : "" }}</label
          >
          <span></span>
       <!--  </div> -->
       <b-form-input
          v-if="['text', 'email', 'tel', 'number', 'url'].includes(field.type)"
          :id="'input-add-' + field.key"
          v-model="form[field.key]"
          :type="field.type"
          v-maska="field.mask"
          :placeholder="field.placeholder"
          :required="field.required"
        ></b-form-input>
        <b-form-select
                v-if="field.type === 'select'"
                :id="'input-add-' + field.key"
                :placeholder="field.label"
                v-model="form[field.key]"
                :options="field.options"
                :required="field.required"
              >
                <template #first>
                  <b-form-select-option :value="undefined" disabled
                    >Por favor, selecione uma opção</b-form-select-option
                  >
                </template>
              </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col v-for="field in exFields" :key="field.key" :cols="field.col">
      <b-form-group :id="'input-add-group' + field.key">
     <!--    <div class="h-separator"> -->
          <label :for="'input-add-' + field.key" class="title"
            >{{ field.label }}{{ field.required ? " *" : "" }}</label
          >
          <span></span>
       <!--  </div> -->
       <b-form-input
          v-if="['text', 'email', 'tel', 'number'].includes(field.type)"
          :id="'input-add-' + field.key"
          v-model="form[field.key]"
          :type="field.type"
          v-maska="field.mask"
          :placeholder="field.placeholder"
          :required="field.required"
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  </b-row>
</b-form>
</Content>
</template>
<script>
import Content from '../components/content/index'
import { api /* request */ } from '@/services'
import { toast /* errorFormater */ } from '@/utils'
export default {
  components: { Content },
  data () {
    return {
      form: {},
      selectedSystem: null
    }
  },
  mounted () {
    toast(this, 'success', 'Nova Rota', 'Selecione um sistema para começar')
  },
  beforeCreate () {
    this.$store.dispatch('sistemas/init', { self: this })
  },
  created () {
    if (this.$route.query.group) {
      const editedModule = this.$store.getters.getEditedModule
      this.selectedSystem = editedModule.groups[0].key
      const exFieldsMap = new Map(editedModule.extra_fields.filter(f => f.type_value !== 'script').map(a => [a.key, a.value[0]]))

      for (const field of this.fieldsExtra) {
        if (exFieldsMap.has(field.key)) {
          this.form[field.key] = exFieldsMap.get(field.key)
        } else if (editedModule[field.key]) {
          this.form[field.key] = editedModule[field.key]
        } else {
          this.form[field.key] = '' // Set a default value if no value found
        }
      }
      this.form = { ...this.form }
      console.log(this.form)
    }
  },

  computed: {
    fieldsExtra () {
      return this.$store.state.rotas.formFields
    },
    exFields () {
      return this.$store.state.rotas.extraFields
    },
    action () {
      return { options: { label: 'Salvar', icon: 'save', hasBack: 'arrow' } }
    },
    options () {
      if (this.$store.state.sistemas.items && this.$store.state.sistemas.items.groups) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map(a => a[1])
        const value = item.map(a => ({ value: a.id, text: a.key }))
        return value.map(v => v.text)
      }
      return []
    },
    allSystems () {
      if (this.$store.state.sistemas.items && this.$store.state.sistemas.items.groups) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1])
        const value = item.map((a) => ({ value: a.id, text: a.key }))
        return value.map((v) => v.text)
      }
      return []
    }

  },
  methods: {
    createRoute () {
      this.form = {
        ...this.form,
        key: this.selectedSystem + '_' + this.form.object_name,
        group_key: this.selectedSystem
      }
      const promise = this.$route.query.group ? api.put('/object/update/' + this.$store.getters.getEditedModule.id, this.form) : api.post('/object/store', this.form)
      promise.then(res => {
        toast(this, 'success', 'Rota Cadastrada', 'Rota cadastrada com sucesso')
        this.$router.back()
      }, e => {
        toast(this, 'danger', 'Erro ao criar Rota', e)
        return Promise.reject(e)
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../style/*.styl';

.select
    border-width: 0.1px
    opacity 0.7
    border-color: info
    border-style: solid

.modulo_tag{
    height: 2rem;
    font-size: 1.5rem;
}
.wrapper
    padding-top: 0 !important

#select-title{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: min-content
    align-content: center;
}

#clonar_dicionarios {
    background-color: #fff;
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px;
}

#clonar_dicionarios2 {
    background-color: #fff;
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px;
}

#rotas {
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px
}
.btns
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    align-content: center;

.title-btn
    margin-left auto
    background-color primary
    color white
    border-radius 8px
    padding 10px 12px
    cursor pointer
    transition 0.2s

  .label
    margin-left 5px

  span, .icon-btn
    vertical-align middle
    no-select()

</style>
